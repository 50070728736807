<template>
  <div class=container>
      <table class=table border="0" cellpadding="0" cellspacing="30" width="709">
<tbody><tr>
<td>
  <div align="center">
  <font size="+2"></font>
  </div>
              
<br>
<br>
<h3>Documentation</h3>

The acquisition and the modeling details of our work can be referred to
the following paper:<br>

<blockquote>
<div class="publication" data-v-0ff51178=""><b data-v-0ff51178="">"Time-Varying BRDFs,"<br data-v-0ff51178=""></b><span data-v-0ff51178="">B. Sun, K. Sunkavalli, R. Ramamoorthi, P. Belhumeur and S.K. Nayar,<br data-v-0ff51178=""></span><span data-v-0ff51178="">IEEE Transactions on Visualization and Computer Graphics,<br data-v-0ff51178=""></span><span data-v-0ff51178="">Mar. 2007<br data-v-0ff51178=""></span> [<a class="url" href="http://www1.cs.columbia.edu/CAVE/publications/pdfs/Sun_TVCG07.pdf" target="new" data-v-0ff51178="">PDF</a>] [<a href="reference?bid=223" class="url" data-v-0ff51178="">bib</a>] [<a href="copyright" class="url" data-v-0ff51178="">©</a>] <span data-v-0ff51178=""><a href="projects/categories/project?cid=Computational+Imaging&amp;pid=Time-Varying+BRDFs" class="url" data-v-0ff51178=""> [Project Page]</a></span><br data-v-0ff51178=""></div>
</blockquote>

<br>
<hr style="width: 100%; height: 2px;">
<h3>Acquisition Rig</h3>

This video illustrates our acquisition rig in action:
<br>
<br>

<div style="text-align: center;">
  <a href="https://cave.cs.columbia.edu/old/databases/tvbrdf/videos/slides_system_sampling_comp.avi"><img src="https://cave.cs.columbia.edu/old/databases/tvbrdf/doc_files/setup.png" border="0" height="384" width="640"></a></div>

<br>

As shown in the above figure, our system is composed of four key
components: 
<ul>
<li>Four Dragonfly color cameras (operating at 25 fps and 640 x 480 resolution) mounted on an
aluminum frame</li>
<li>A sample plate with adjustable tilt</li>
<li>A programmable Adept robot</li>
<li>A light arm holding a halogen light source and a diffuser.</li>
</ul>
The four cameras lie in a vertical
plane. All the camera optical axes pass through the center of 
the sample plate, which has four extensible legs to adjust its 
height and tilt. All sample materials are prepared as planar patches 
and placed on a tray on the plate, as shown in the inset
of the figure. The light source has a stable radiant intensity
and the diffuser is used to make the irradiance uniform over
the entire sample. The robot moves the light source around
the sample plate along a semi-circle.<br>

<br>
This design affords us two critical advantages over other BRDF measurement
systems:
<ol>
<li>
We are able to scan materials very quickly. Every semi-circle the light
source navigates takes 12 seconds. We move the light source across the sample 3 times
and capture images with varying intensities. Each measurement thus takes only 36 seconds 
and this enables us to sample the time axis of the TVBRDFs very finely.<br>
<br>
</li><li>
We are able to capture radiance values at every half degree of the plane
of incidence (12 seconds  * 180 degrees / 25 fps = 0.5 degrees / frame). This dense
sampling allows us to measure and fits specularities accurately even for very shiny materials.

</li></ol>
</td></tr>
          
<tr>
  <td>
  <hr style="width: 100%; height: 2px;">
  <router-link to="/repository/TVBRDF">TVBRDF Database Home</router-link><br>
  Contact:&nbsp;<a href="mailto:tvbrdf@cs.columbia.edu">tvbrdf@cs.columbia.edu</a><br>
  Last modified: 06/03/2007
  </td>
</tr>

</tbody></table>
  </div>
</template>

<script>
export default {

}
</script>

<style>
.container{
    width:70%;
    margin-left:auto;
    margin-right:auto;
    text-align: left;
	font-size: 18px;
}
.table{
        margin:auto;
}
</style>